var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "proxy", staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-md-12 col-lg-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailInfo",
              attrs: {
                title: "서명을 입력하세요.",
                topClass: "topcolor-orange",
              },
            },
            [
              _vm.data.approvalStatusCd === "ASC9999999"
                ? _c(
                    "template",
                    { slot: "card-description" },
                    [
                      _c("font", { attrs: { color: "#C10015" } }, [
                        _vm._v(" ※ 완료된 회의입니다. "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.data.approvalStatusCd !== "ASC9999999"
                        ? _c("c-btn", {
                            attrs: {
                              label: "저장",
                              color: "blue",
                              showLoading: false,
                            },
                            on: { btnClicked: _vm.saveAsSvg },
                          })
                        : _vm._e(),
                      _vm.data.approvalStatusCd !== "ASC9999999"
                        ? _c("c-btn", {
                            attrs: {
                              label: "서명지우기",
                              color: "red",
                              showLoading: false,
                            },
                            on: { btnClicked: _vm.eraseSignature },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12" },
                    [
                      _c("VueSignaturePad", {
                        ref: "signaturePad",
                        attrs: {
                          id: "signature",
                          width: _vm.windowWidth,
                          height: "200px",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "참석회의 정보" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: true,
                    name: "proceedingsTitle",
                    label: "회의제목",
                  },
                  model: {
                    value: _vm.data.proceedingsTitle,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "proceedingsTitle", $$v)
                    },
                    expression: "data.proceedingsTitle",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-datepicker", {
                  attrs: {
                    disabled: true,
                    name: "proceedingsDate",
                    label: "회의일",
                  },
                  model: {
                    value: _vm.data.proceedingsDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "proceedingsDate", $$v)
                    },
                    expression: "data.proceedingsDate",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }