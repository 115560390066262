<template>
  <div class="row" ref="proxy">
    <div class="col-md-12 col-lg-12">
      <c-card title="서명을 입력하세요." class="cardClassDetailInfo" topClass="topcolor-orange">
        <template v-if="data.approvalStatusCd === 'ASC9999999'" slot="card-description">
          <font color="#C10015">
            ※ 완료된 회의입니다.
          </font>
        </template>
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="data.approvalStatusCd !== 'ASC9999999'" label="저장" color="blue" :showLoading="false" @btnClicked="saveAsSvg" />
            <c-btn v-if="data.approvalStatusCd !== 'ASC9999999'" label="서명지우기" color="red" :showLoading="false" @btnClicked="eraseSignature" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="row">
            <div class="col-xs-12 col-sm-12">
               <VueSignaturePad id="signature" :width="windowWidth" height="200px" ref="signaturePad" />
            </div>
          </div>
        </template>
      </c-card>
    </div>
    <c-card title="참석회의 정보" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :disabled="true"
            name="proceedingsTitle"
            label="회의제목"
            v-model="data.proceedingsTitle"
          ></c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-datepicker
            :disabled="true"
            name="proceedingsDate"
            label="회의일"
            v-model="data.proceedingsDate"
          ></c-datepicker>
        </div>
      </template>
    </c-card>
  </div>
</template>
<script>
import VueSignaturePad from 'vue-signature-pad';
import Vue from 'vue';
import mobileConfig from '@/js/mobileConfig';
import selectConfig from '@/js/selectConfig';
let signaturePad = null;
Vue.use(VueSignaturePad)
export default {
  name: 'signature-pop',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          proceedingsId: '',
          checkAttendee: '',
          regUserId: '',
          chgUserId: '',
        }
      },
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      updateSignatureUrl: '',
      getUrl: '',
      windowWidth: '',
      data: {
        attendeeUserId: '',
        proceedingsId: '',
        proceedingsTitle: '',
        proceedingsDate: '',
        electronSignature: '',
        attendeeSignatureFlag: '',
        approvalStatusCd: '',
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.data.approvalStatusCd === 'ASC9999999'
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.updateSignatureUrl = mobileConfig.sai.proceeding.update.signature.url;
      this.getUrl = mobileConfig.sai.proceeding.get.mobile.url;
      this.getUserDetail();
      if (!this.$refs.signaturePad) {
        return;
      }
      this.windowWidth = (document.documentElement.clientWidth - 45) + 'px';
      signaturePad = this.$refs.signaturePad
    },
    getUserDetail() {
      if (this.popupParam.proceedingsId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.proceedingsId, this.$store.getters.user.userId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data) {
            this.data = _result.data
            signaturePad.fromDataURL(this.data.electronSignature);
            this.getDetail();
          } else {
            this.data.proceedingsId = this.popupParam.proceedingsId
            this.data.attendeeUserId = this.$store.getters.user.userId
            this.getDetail();
          }
        })
      }
    },
    getDetail() {
      if (this.popupParam.proceedingsId) {
        this.$http.url = this.$format(selectConfig.sai.osh.proceedings.get.url, this.popupParam.proceedingsId)
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data) {
            if (_result.data.approvalStatusCd === 'ASC9999999') {
              signaturePad.lockSignaturePad();
            }
            this.data.proceedingsTitle = _result.data.proceedingsTitle
            this.data.proceedingsDate = _result.data.proceedingsDate
          }
        })
      }
    },
    saveAsSvg() {
      var data = ''
      if (signaturePad.saveSignature().isEmpty) {
        data = ''
      } else {
        data = signaturePad.saveSignature().data;
      }
      this.data.electronSignature = data;
      
      if (!this.data.electronSignature) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '서명하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '서명등록하시겠습니까?\n\r(등록할 시 참석이 완료 됩니다.)',
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.data.regUserId = this.$store.getters.user.userId
            this.data.chgUserId = this.$store.getters.user.userId
            this.$http.url = this.updateSignatureUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.data;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$emit('closePopup');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          
          },
        });
      }
    },
    eraseSignature() {
      signaturePad.clearSignature();
      this.data.electronSignature = '';
    },
    reset() {
      Object.assign(this.$data.info, this.$options.data().info);
    },
  }
};
</script>
<style>
#signature {
  border: double 1px transparent;
  border-radius: 2;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>